.navbar{
    // padding: 28px 0;
    top: 0;
    position: fixed;
    width: 100%;
    overflow: hidden;
    z-index: 99;
    background-color: transparent;
    transition: padding-top 0.3s, padding-bottom 0.3s, background-color 0.8s;
}
.navbar-nav-mrg-lft{
    margin-left: auto;
    
}
.razberry-logo{
    margin-top: 30px;
    transition: margin-top 0.3s;
}
.navbar-collapse{
    padding-top: 30px;
    transition: padding-top 0.3s;
}
.navbar-nav-mrg-lft li a{
    color: #fee5bb;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    
    //
    text-shadow:0 0 6px #07162e;
    font-weight: 400;
    letter-spacing: 0.50px;
    
}
#mySidenav .navbar-nav-mrg-lft li a{
    color: #eed4aa;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 25px;
    
}
.sidenav-logo-img{
    padding-left: 25px;
}
#mySidenav .navbar-nav-mrg-lft{
padding-top: 30px;
}
.navbar-dark .navbar-nav .nav-link{
    color: #eed4aa;
}
.nav-reservation-btn{
    background-color: #94733c;
    border:2px solid #94733c;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    padding: 6px 20px;
    margin-left: 25px;
}
.nav-location{
    color: #dab57a;
    max-width: 35px;
    padding-left: 10px;
    padding-top: 3px;
}
.nav-container{
    position: relative;
}
.nav-info{
    background-color:#00162e;
//     margin-top: -64px;
//     margin-left: -430px;
//    margin-bottom: 10px;
    position: absolute;
    right: 15px;
    top: -8px;
}
.nav-call-h3{
    font-size: 11px;
    color: #b1b1b1;
    letter-spacing: 0.3px;
    margin-bottom: 0;
    padding-left:13px;
    padding-right: 13px;
    padding-top: 5px;
    padding-bottom: 6px;
    font-family: 'Montserrat', sans-serif;
}
.nav-tel-a:hover{
    text-decoration: none;
    color: #fff;
}
.nav-tel-a{
    text-decoration: none;
    color: #a7926f;
    font-size: 12px;
    letter-spacing: 0.3px;
    font-family: 'Montserrat', sans-serif;

}
.navbar-bg{
    background-color: transparent;

}
// .navbar-expand-md .navbar-nav .nav-item:active a{
//     border-bottom: 1px solid #94733c;
// }
#collapsibleNavbar .navbar-nav li.active > a {
    border-bottom: 1px solid #94733c;
   
    margin-bottom: -1px;
}
.res-click{
    background-color: transparent;
    border:2px solid #94733c;
    border-radius: 30px;
    padding: 6px 20px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
}

//
.navbar.shrink{
    background-color: #00162e;
    .razberry-logo{
        margin-top: 0;
    }
    .navbar-collapse{
        padding-top: 0;
    }   
}

.menu-icon div{
    width: 28px;
    height: 3px;
    background-color: #efd5aa;
    margin: 6px 0;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    background-color: #00162e;
    overflow-x: hidden;
    transition: 0.2s;
    padding-top: 20px;
   //

  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #efd5aa;
    display: block;
    transition: 0.3s;
  
  }
  
  .sidenav a:hover{
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    font-size: 65px;
    line-height: 65px;
    margin-left: 50px;
  }
  .sweep-to-top {
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
    background-color: #94733c;
    border:2px solid #94733c;
    color: #ffffff;
    border: none;
    border-radius: 30px;
    padding: 6px 20px;
    margin-left: 25px;
  }
  .sweep-to-top:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00162e;
    border: 2px solid #94733c;
    border-radius: 30px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
  }
  .sweep-to-top:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    
  }
  .res-btn-mobile{
      margin-top: 100px;

  }
  .res-cls{
      text-align: center;
  }