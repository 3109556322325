@font-face {
    font-family: 'OptimusPrinceps';
    font-style: normal;
    font-weight: normal;
    src: local('OptimusPrinceps'), url('../fonts/OptimusPrinceps.woff') format('woff');
}  
@font-face {
    font-family: 'OptimusPrincepsSemiBold';
    font-style: normal;
    font-weight: normal;
    src: local('OptimusPrincepsSemiBold'), url('../fonts/OptimusPrincepsSemiBold.woff') format('woff');
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import "includes/custom";
@import "includes/bootstrap";
@import "includes/lightbox";
// @import "custom/datepicker";
@import "custom/mydate";
@import "custom/animate";
@import "custom/slidewiz";
@import "custom/navbar";
@import "custom/home";
@import "custom/about";
@import "custom/testimonial";
@import "custom/gallery";
@import "custom/reservation";
@import "custom/footer";
@import "custom/mobile";
