.home-second-sec{
    padding: 70px 0;
}
.home-wedding-img{
    max-width: 100%;
}
.home-sweet-img{
    max-width: 100%;
}
.home-wedding-card{
    background-image: url("../images/home-wedding-card.jpg");
    background-repeat: no-repeat;
    background-position: bottom right; 
    margin-right: 0;
}
.home-third-sec{
    background-image: url("../images/third-sec-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 70px;
    height: 478px;
    // padding-bottom: 100px;
}
.wedding-gallery-h3{
    color: #0d1d3a;
    font-size: 18px;
    letter-spacing: 1.35px;
    text-transform: uppercase;
}
.wedding-gallery-p{
    color: #8d98a1;
    font-size: 12px;
    line-height: 24px;
    
}
.home-wedding-card-bg{
    box-shadow: 0px 17px 62px 0 rgba(36, 99, 157, 0.15);
    background-color: #ffffff;
    padding-top: 13px;
    padding-left: 14px;
    
}
.home-wedding-card-bg-override{
    max-width: 525px;
    margin: 0 auto;
    margin-top: -30px;
}
.line-img{
    background-image: url("../images/home-line.jpg");
    background-repeat: no-repeat;
    padding-top: 24px;
    padding-left: 17px;
    padding-bottom: 42px;
}
.home-wedding-link{
    color: #0d1d3a;
    font-size: 12px;
    text-decoration: none;
    letter-spacing: 0.9px;
}

.download-brochure-a{
    color: #ffffff;
    font-size: 12px;
    text-decoration: none;
}
.reservation-div{
    margin: 0 auto;
    text-align:center;
    margin-bottom: 10px;
  
}
.making-moments-memorable-h3{
    color: #efd5aa;
    font-size: 24px;
    letter-spacing: 1.8px;
    // padding-bottom: 56px;
    text-align: center;

}
// .reservation-btn{
//     margin-left: 236px;
// }
.youtube-bg{
    margin: 0 auto;
    max-width: 800px;
    background-image: url('../images/home-wedding.jpg');
    background-repeat: no-repeat;
    height: 400px;
    // margin-left: 250px;
}
.youtube-play-icon{
    position: relative;
    margin-top: -280px;
    box-shadow: 0px 17px 62px 0 rgba(36, 99, 157, 0.15);
    max-width: 100%;
}
.youtube-icon-div{
    margin: 0 auto;
    text-align: center;
}
.why-choose-h3{
    color: #0d213e;
    font-size: 24px;
    letter-spacing: 1.8px;
    text-align: center;
    padding-top: 75px;
    
}
.why-choose-p{
    color: #8d98a1;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    max-width: 850px;
    margin: 0 auto;
}
.right-arrow{
    max-width: 12px;
    margin-left: 6px;
}
.know-more-p{
    text-align: center;
    padding-top: 35px;
    margin-bottom: 0;
    
}
.home-fourth-sec{
    padding-bottom: 70px;
}
.home-five-sec{
    background-color: #eaecff;
    padding-bottom: 70px;
    padding-top: 70px;
}
.Making-Moments-Memorable-h3{
    font-size: 24px;
    letter-spacing: 1.8px;
    color: #0f132c;
    text-align: center;
    padding-bottom: 60px;
}
.graduation-parties-a{
    font-size: 18px;
    letter-spacing: 1.35px;
    color: #0d1d3a;
    font-family: 'OptimusPrincepsSemiBold', 'Montserrat', sans-serif;
}
.graduation-parties-div{
    padding-top: 26px;
    padding-left: 19px;
    padding-bottom: 30px;
    padding-right: 50px;
    background-color: #ffffff;
}
.graduation-parties-div-one{
    padding-bottom: 55px;
}
.graduation-img{
    width: 100%;
}
.bar-mitzvah-ny-img{
    width: 100%;
    height: 100%;
}
.bar-mitzvah-ny-h3{
    color: #0d1d3a;
    letter-spacing: 1.35px;
    font-size: 18px;
    
}
.bar-mitzvah-ny-data{
    background-color: #ffffff;
    padding-top: 26px;
    padding-left: 23px;
    padding-bottom: 70px;
}
.prom-div{
    margin-top: 22px;
}
.bar-bat-col{
    padding: 0;
    margin: 0;
}
.home-six-sec{
    padding-top: 70px;
    padding-bottom:70px;
}
.testimonials-h3{
    color: #0d213e;
    font-size: 24px;
    letter-spacing: 1.8px;
    text-align: center;
}
.best-h3{
    color: #0d1d3a;
    font-size: 20px;
    letter-spacing: 0.9px;
    
}
.best-banquet-row{
    padding-top: 40px;
}
.best-p{
    color: #8d98a1;
    font-size: 14px;
    line-height: 24px;
    
}
.home-seven-sec{
    padding-bottom: 20px;
    padding-top: 50px;
}
// .bar-mitzvah-ny-data-second{
//     padding-bottom: 47px;
// }
.error{
    color: red;
    font-size: 12px;
    margin-left: 10px;
    display: block;
}
.desktop-view{
    display: block;
}
.mobile-view{
    display: none;
}
#headerCarousel{
    .carousel-item {
        height: 100vh;
        min-height: 350px;
        background: no-repeat center center scroll;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        opacity: 0.8;
      }
      .carousel-inner{
        background-color: #000;
      }
      .carousel-caption{
          top:50%;
      }
      .carousel-indicators{
          top:85%;
      }
      .carousel-h3{
       
       
        color: #fff;
        font-family: 'OptimusPrincepsSemiBold', 'Montserrat', sans-serif;
        font-size: 24px;
        font-weight: bold;
        word-wrap: break-word;
        text-align: center;
        letter-spacing: 1.8px;
      }
      .hr-in-slider{
        border-top: 1px solid #efd5aa;
        max-width: 340px;
    }
    .detail-desc {
        width: 100%;
        // float: left;
        // margin-top: 5px;
        color: #fff;
        line-break: 24px;
        font-family: 'Montserrat', sans-serif;
        font-size: 16px;
        word-wrap: break-word;
      
    }
    // .carousel .carousel-indicators {
    //     bottom: -40px;
    // }
    .carousel-indicators li, .carousel-indicators li.active {
        width: 8px;
        height: 8px;
        margin: 1px 3px;
        border-radius: 50%;
    }
    .carousel-indicators li {	
        background: #ffffff;
        border-color: transparent;
        box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
        border-top: 0;
        border-bottom: 0;
    }
    .carousel-indicators li.active {	
        background: #b19f82;		
        box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
    }
}
// .download-brochure-btn{
//     background-color: #94733c;
//     color: #ffffff;
//     text-decoration: none;
//     border-radius: 30px;
//     padding: 0 10px;
//     border:none;
// }
.download-brochure-btn {
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
    background-color: #94733c;
    color: #ffffff;
    text-decoration: none;
    border-radius: 30px;
    padding: 2px 20px;
    border:none;
  }
  .download-brochure-btn:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00162e;
    border: 2px solid #94733c;
    color:#fff;
    border-radius: 30px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
  }
  .download-brochure-btn:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    
  }
  .download-brochure-btn a:hover{
    color: #fff;
  }
  .download-brochure-btn a{
      text-decoration: none;
  }