@media only screen and (max-width: 768px)  {
    .navbar-collapse {
        position: absolute;
        top: 100px;
        right: 100%;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 100%;
        transition: all 0.3s ease;
        display: block;
    }
    .razberry-logo{
        margin-top: 8px;
    }
    #headerCarousel .carousel-indicators{
        top:90%;
    }
    .reservation-btn{
        margin-left: 0;
    }
    .home-sweet-sec-row{
        margin-top:20px;
    }
    .making-moments-memorable-h3{
        padding-bottom: 0;
    }
    .home-third-sec{
        height: 420px;
    }
    .why-choose-h3{
        padding-top:25px;
    }
    .Making-Moments-Memorable-h3{
        padding-bottom: 40px;
    }
    .event-type-mobile{
        margin-top: 20px;
    }
    // .graduation-img{
    //     width:80%;
    // }
    .bar-mitzvah-ny-img{
        max-width: 100%;
        padding: 0 30px;
       height: auto;
    }
    .graduation-parties-div{
        max-width: 100%;
        margin:0 auto;
        text-align: left;
    }
    .graduation-parties-div-one{
        padding-bottom: 20px;
    }
    .col-middle-event{
        text-align: center;
    }
    .bar-bat-col{
        text-align: center;
        width: 100%;
    }
    .bar-bat-col-data{
        max-width:100%;
        padding: 0 30px;
        margin: 0 auto;
        text-align: left;
    }
    .bar-mitzvah-ny-data{
        padding-bottom: 30px;
    }

    // .bar-mitzvah-ny-img{
    // padding-left: 3px;
    // padding-right: 3px;
    // }
    // .bar-bat-col{
    //     padding-left: 8px;
    //     padding-right: 8px;
    // }
    .navbar-collapse.collapsing {
        height: auto !important;
        margin-right: 50%;
        transition: all 0.3s ease;
        display: block;
    }
    .navbar-collapse.show {
        right: 0;
        background-color: red;
    }
    .col-middle-event{
        width:100%;
        padding: 0 30px;
    }
    .graduation-img{
        max-width: 100%;
    }
    .gallery-txt-sec-img{
        padding-right: 0;
    }
    .mobile-view{
        display: block;
    }
    .desktop-view{
        display: none;
    }
    .res-cls{
        padding-left:0 !important; 
        text-align: center !important;
    }
    .res-btn-mobile{
        margin-left: 0 !important;
    }
    .footer-axlr-logo-img{
        max-width: 100px;
    }
    .footer-p{
        padding-bottom: 0;
    }
    .best-banquet-row{
        padding-top: 0;
    }
    .home-six-sec{
        padding-top: 30px;
        padding-bottom: 30px;
    }
    .razCarousel-container .razCarousel-card{
        margin: 30px;
    }
    .razCarousel-container .carousel .carousel-indicators{
        bottom: 0;
    }
    .footer-h3-mobile{
        padding-top: 15px;
    }
    .footer-col-adj{
        padding-bottom: 20px;
    }
    .reservation-second-sec{
        padding-bottom: 0;
    }
    .reservation-form-data{
        margin: 50px;
    }
}