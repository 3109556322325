// Variables
//
// Copy settings from `node_modules/bootstrap/scss/_variables.scss` into this file to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

h1,h2,h3,h4,h5,h6{
    font-family: 'OptimusPrincepsSemiBold', 'Montserrat', sans-serif;
}
*,p{
    font-family: 'Montserrat', sans-serif;
}