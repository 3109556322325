footer{
    background-color: #00162e;
    padding-top: 50px;
}

.footer-h3{
    color: #caa25f;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
}

.footer-ul{
    font-family: 'Montserrat', sans-serif;
    list-style: none;
    color: #959596;
    padding-left: 0;
    margin-top: 20px;
}

.footer-ul li a{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #959596;
}

.footer-ul li{
    margin-bottom: 5px;
}

.footer-h4{
    color: #94733c;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-top: 25px;
}

.footer-p{
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #959596;
    line-height: 26px;
    padding-bottom: 10px;
}

a.footer-p1-a{
    font-family: 'Montserrat', sans-serif;
    background-color: #c7a05e;
    color: #ffffff;
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 14px;
}

.footer-h3-a{
    color: #959596;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}

.footer-top-border{
    border-top: 1px solid #4e4e4e;
    padding: 15px 0;
}
.footer-top-border p{
    color: #959596;
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

// success modal start
.formsucess_modalsucess{
    svg {
        width: 71px!important;
        display: block;
        margin: 40px auto 0;
      }
      .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 0;
      }
      .path.circle {
        -webkit-animation: dash 0.9s ease-in-out;
        animation: dash 0.9s ease-in-out;
      }
      .path.line {
        stroke-dashoffset: 1000;
        -webkit-animation: dash 0.9s 0.35s ease-in-out forwards;
        animation: dash 0.9s 0.35s ease-in-out forwards;
      }
      .path.check {
        stroke-dashoffset: -100;
        -webkit-animation: dash-check 0.9s 0.35s ease-in-out forwards;
        animation: dash-check 0.9s 0.35s ease-in-out forwards;
      }
      p {
        text-align: center;
        margin: 20px 0 60px;
        font-size: 1.25em;
      }
      p.success {
        color: #73AF55;
      }
      p.error {
        color: #D06079;
      }
      @-webkit-keyframes dash {
        0% {
          stroke-dashoffset: 1000;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes dash {
        0% {
          stroke-dashoffset: 1000;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @-webkit-keyframes dash-check {
        0% {
          stroke-dashoffset: -100;
        }
        100% {
          stroke-dashoffset: 900;
        }
      }
      @keyframes dash-check {
        0% {
          stroke-dashoffset: -100;
        }
        100% {
          stroke-dashoffset: 900;
        }
      }
    }
  // success modal end