.razCarousel-container{
    .carousel .item {
        color: #999;
        font-size: 14px;
        text-align: center;
        overflow: hidden;
        margin-bottom: 50px;
    }
    .carousel .testimonial {
        padding: 30px 0 10px;
    }
    .carousel .carousel-control {
        width: 40px;
        height: 40px;
        margin-top: -20px;
        top: 50%;
        background: none;
    }
    .carousel-control i {
        font-size: 68px;
        line-height: 42px;
        position: absolute;
        display: inline-block;
        color: rgba(0, 0, 0, 0.8);
        text-shadow: 0 3px 3px #e6e6e6, 0 0 0 #000;
    }
    .carousel .carousel-indicators {
        bottom: -40px;
    }
    .carousel-indicators li, .carousel-indicators li.active {
        width: 10px;
        height: 10px;
        margin: 1px 3px;
        border-radius: 50%;
    }
    .carousel-indicators li {	
        background: #999;
        border-color: transparent;
        box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
        border-top: 0;
        border-bottom: 0;
    }
    .carousel-indicators li.active {	
        background: #555;		
        box-shadow: inset 0 2px 1px rgba(0,0,0,0.2);
    }
    .razCarousel-card{
        padding: 30px;
        box-shadow: 0px 12px 50px 0 rgba(36, 99, 157, 0.15);
        margin: 60px 30px;
        background-color: #ffffff;
    }
    .razCarousel-card-p{
        font-size: 14px;
        color: #8d98a1;
        text-align: left;
        line-height: 24px;
    }
    .razCarousel-card-p1{
        text-align: left;
        font-size: 14px;
        font-weight: 600;
        font-family: 'OptimusPrincepsSemiBold', 'Montserrat', sans-serif;
        color: #0d1d3a;
    }
    .razCarousel-card-p1 span{
        color: #8d98a1;

    }
    .carousel-control-prev{
        left:-68px;
    }
    .carousel-control-next{
        right:-68px;
    }
    .testimonials-arrow{
        max-width: 25px;
    }
    .carousel-control-prev{
        opacity: 1;
        // max-width: 20px;
    }
    .carousel-control-next{
        opacity: 1;
        // max-width: 20px;
    }
}