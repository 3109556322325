.reservation-second-sec{
    padding-bottom: 157px;

}
.reservation-form-bg{
    background-image: url("../images/enquiry.jpg");
    background-repeat: no-repeat;
    background-size:100% 100%;
    height: 100%;

}
.reservation-media-img{
    max-width: 100%;
    height: 100%;
}
.new-media-row .media{
    width:100%;
}
// .latest-resvervation-img{
//     max-width: 100%;
//     // height: fit-content;
// }
.reservtion-padd{
    padding: 0;
    // margin: 0;
}
.name-input{
    background-color: #f6f6f6;
    border: none;
    color: #585858;
    font-size: 14px;
    line-height: 24px;
    margin-top:10px;
    margin-bottom: 8px;
}
.reservation-form{
    box-shadow: 0px 17px 62px 0 rgba(36, 99, 157, 0.15);
    background-color: #ffffff;
    position: relative;
    margin-top: -125px;
}
.reservation-form-data{
    margin-left: 93px;
    margin-right: 93px;
    // padding-bottom: 40px;
    margin-top:40px;
    margin-bottom: 40px;
    // box-shadow: 0px 17px 62px 0 rgba(36, 99, 157, 0.15);
    background-color: #ffffff;
   
  
}
.reservation-media{
    box-shadow: 0px 17px 62px 0 rgba(36, 99, 157, 0.15);
}
.reservation-media .media-body{
    padding-bottom: 40px;
}
.head-banner-reservation{
    padding: 230px 0;
}
.btn-reservastion-a{
    text-decoration: none;

}
.btn-reservastion-btn{
    background-color: #94733c;
    border:none;
    border-radius:30px;
    padding: 7px 30px;
    margin-left: 10px;
    margin-top: 10px;
    color: #ffffff;
    font-size: 12px;
}