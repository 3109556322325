.head-banner{
    background-image: url('../img/gallery-banner-bg.jpg');
    padding: 150px 0;
    text-align: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.head-banner-h2{
    color: #efd5aa;
    font-size: 30px;
    letter-spacing: 1px;
}
.head-banner-p{
    color: #b19f82;
    font-size: 18px;
    max-width: 700px;
    margin: 0 auto;
}
.head-banner-divided{
    max-width: 400px;
    margin: 20px auto;
    border-bottom: 1px solid #b19f82;
}

.gallery-sec{
    background: rgb(13,52,92);
    background: radial-gradient(circle, rgba(13,52,92,1) 0%, rgba(8,22,50,1) 49%, rgba(13,14,41,1) 100%);
    padding: 70px 0 70px;
}

.gallery-sec-img{
    width: 100%;
    margin-bottom: 30px;
}

.gallery-sec-p-reservation{
    font-size: 24px;
    color: #efd5aa;
    font-family: 'OptimusPrincepsSemiBold', 'Montserrat', sans-serif;
    line-height: 44px;
    margin-left: 36px;
}
.gallery-txt-p{
    font-size: 18px;
    line-height: 40px;
    font-family: 'OptimusPrincepsSemiBold', 'Montserrat', sans-serif;
    margin-right: 45px;
    text-transform: uppercase;
    letter-spacing: 1.2px;
    color: #0d1d3a;
}
.gallery-txt-p1{
    color: #8d98a1;
    font-size: 14px;
    line-height: 28px;

}
.gallery-txt-sec{
    padding: 70px 0;
}
.gallery-txt-sec-img{
    max-width: 100%;
    padding-right: 70px;
}
.lightbox .lb-image{
    border:none;
}
// .gallery-sec-a-reservation{
//     font-family: 'Montserrat', sans-serif;
//     background-color: #c7a05e;
//     color: #ffffff;
//     border-radius: 50px;
//     padding: 5px 10px;
//     font-size: 14px;
// }
.gallery-sec-a-reservation {
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
    font-family: 'Montserrat', sans-serif;
    background-color: #c7a05e;
    color: #ffffff;
    border-radius: 50px;
    padding: 5px 10px;
    font-size: 14px;
  }
  .gallery-sec-a-reservation:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00162e;
    border: 2px solid #94733c;
    border-radius: 30px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
  }
  .gallery-sec-a-reservation:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    
  }
  .gallery-sec-a-reservation:hover{
      text-decoration: none;
  }
  .wed-reservation {
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
    background-color: #94733c;
    border:none;
    border-radius: 30px;
    color: #ffffff;
    text-align: left;
    padding:6px 20px;
  }
  .wed-reservation:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00162e;
    border: 2px solid #94733c;
    border-radius: 30px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
  }
  .wed-reservation:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    
  }
  .wed-reservation a{
      text-decoration: none;
      color:#fff;
  }
  .wed-reservation{
      margin-left: 25px;
  }