.about-two-sec{
    padding-bottom: 70px;
    padding-top: 70px;
}
.abt-p{
    color: #8d98a1;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 0.35px;

}
.abt-h3{
    font-size: 20px;
    line-height: 40px;
    letter-spacing: 1.35px;
    color: #0d1d3a;
    
    padding-bottom: 50px;
}
.abt-wedding-img{
    max-width: 100%;
    margin-top: 10px;
}
.abt-third-sec{
    background-image: url("../images/third-sec-bg.jpg");
    background-repeat:no-repeat;
    background-size: cover;
    padding:70px 0;
}
.youtube-play-icon-abt{
    position: relative;
    box-shadow: 0px 17px 62px 0 rgba(36, 99, 157, 0.15);
}
.abt-making-h3{
    color: #efd5aa;
    font-size: 24px;
    line-height: 48px;
    letter-spacing: 1.8px;
    max-width: 260px;
    margin: 0 auto;
    margin-top: 100px;
    text-align: left;

}

.abt-reservation a{
    color: #ffffff;
}
.making-div{
    margin: 0 auto;
    text-align: center; 
}
.abt-fourth-sec{
    padding-top: 70px;
    padding-bottom: 70px;

}
.abt-why-choose{
    color: #0d213e;
    font-size: 24px;
    letter-spacing: 1.8px;
    padding-bottom: 40px;
    text-align: center;
}
.abt-why-choose-p{
    color: #8d98a1;
    font-size: 14px;
    line-height: 30px;
    padding-bottom: 120px;
    text-align: center;
    max-width: 820px;
    margin: 0 auto;
}
.abt-mission-h3{
    color: #0d213e;
    font-size: 24px;
    letter-spacing: 1.8px;
    padding-bottom: 20px;
}
.abt-mission-p{
    color: #8d98a1;
    font-size: 14px;
    line-height: 30px;
    
}
.about-test-sec{
    padding: 70px;
    background-color: #eaecff;
}
.youtube-play-icon-abt{
    width:100%;
}
// .abt-reservation{
//     background-color: #94733c;
//     border:none;
//     border-radius: 30px;
//     color: #ffffff;
//     text-align: left;
//     padding:6px 20px;
// }
.abt-reservation {
    position: relative;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: color 1000ms;
    transition: color 1000ms;
    background-color: #94733c;
    border:none;
    border-radius: 30px;
    color: #ffffff;
    text-align: left;
    padding:6px 20px;
  }
  .abt-reservation:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #00162e;
    border: 2px solid #94733c;
    border-radius: 30px;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition: 300ms ease-out;
    transition: 300ms ease-out;
  }
  .abt-reservation:hover:before {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    
  }
  .abt-reservation a{
      text-decoration: none;
  }
  .abt-reservation {
      margin-left: -60px;
  }